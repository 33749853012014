<template>
    <div class="apply">
        <div class="header-wrapper d-flex justify-content-center align-items-center">
            <div class="container text-center text-light header-container">
                <h1 class="display-4">Die MCONE Adventure Story</h1>
                <p class="lead w-100 mx-auto">
                    Die Adventure Story erzählt eine Geschichte rund um MC ONE.<br>
                    Folge den NPCs, löse Rätsel und finde mehr über den Server hinaus.<br>
                    Dieses Erlebnis findest du nur exklusiv bei MC ONE!
                </p>
            </div>
        </div>

        <b-container>
            <div class="text-center my-5">
                <p class="info-text mx-auto">
                    Zusammen mit deinen Freunden wirst du teil der Geschichte von MC ONE
                    und hast die Möglichkeit überall auf der Karte neue spannende Orte zu entdecken und Aufgaben zu
                    lösen.
                </p>

                <b-button v-b-modal.start-modal size="lg" variant="outline-primary">Jetzt starten</b-button>

                <b-modal id="start-modal" body-class="text-center" header-class="start-modal-header">
                    <h5 class="mb-3">Wie starte ich die Story?</h5>
                    <p>
                        Um die Story zu starten musst du dich in der Lobby zu <mark>Edward</mark> begeben.
                        Du findest ihn mit dem Kompass wenn du auf <code>Die Story</code> klickst.
                    </p>
                    <p>
                        Alles weitere erklärt dir Edward. Viel Spaß!
                    </p>

                    <template slot="modal-footer" class="text-center">
                        <b-button variant="outline-secondary" @click="$bvModal.hide('start-modal')">Verstanden</b-button>
                    </template>
                </b-modal>
            </div>
        </b-container>

        <section class="bg-light">
            <b-container>
                <b-row align-v="start" class="py-5">
                    <b-col lg="6" order="2" order-lg="1" class="text-center text-lg-left">
                        <h2 class="mb-3">Kapitel 1</h2>
                        <hr>
                        <p>
                            Im ersten Teil der Story lernst du einige Bewohner von unserer Lobby-Insel One-Island
                            kennen. Schnell wird klar, dass die Stadt ein großes Problem hat und auf deine Hilfe
                            angewiesen ist!<br>
                            <br>
                            Das erste Kapitel startet am Jacht Hafen in der Nähe vom Spawn.
                            Weitere interessante Orte kannst du bereits schon jetzt besuchen, ohne mit der Story begonnen zu haben.
                        </p>
                    </b-col>
                    <b-col lg="6" order="1" order-lg="2">
                        <b-carousel id="carousel-fade" fade indicators img-width="1024"
                                    img-height="480" class="mb-5 pl-0 mb-lg-0 pl-lg-3">
                            <b-carousel-slide v-for="img in gallery_img.chapter1" v-bind:key="img.caption"
                                              :caption="img.caption" :img-src="img.url"></b-carousel-slide>
                        </b-carousel>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section>
            <b-container>
                <b-row align-v="start" class="py-5">
                    <b-col lg="6">
                        <b-carousel id="carousel-fade" fade indicators img-width="1024"
                                    img-height="480" class="mb-5 pl-0 mb-lg-0 pl-lg-3">
                            <b-carousel-slide v-for="img in gallery_img.chapter2" v-bind:key="img.caption"
                                              :caption="img.caption" :img-src="img.url"></b-carousel-slide>
                        </b-carousel>
                    </b-col>
                    <b-col lg="6" class="text-center text-lg-left">
                        <h4 class="m-0">
                            <b-badge>Comming Soon</b-badge>
                        </h4>
                        <h2 class="mb-3">Kapitel 2</h2>
                        <hr>
                        <p>
                            Das Kapitel zwei ist zur Zeit noch in der Entwicklung. Aber so viel können wir verraten: Es wird spannend.<br>
                            Die Stadt braucht dich, um herauszufinden wer die Ursache aller aufgetretenen Probleme war.
                            Du reist zu neuen Inseln und lernst deren Bewohner kennen, die dir helfen den Fall aufzuklären.
                        </p>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section class="bg-light">
            <b-container>
                <b-row align-v="start" class="py-5">
                    <b-col lg="6" order="1" order-lg="2">
                        <b-carousel id="carousel-fade" fade indicators img-width="1024"
                                    img-height="480" class="mb-5 pl-0 mb-lg-0 pl-lg-3">
                            <b-carousel-slide v-for="img in gallery_img.banktheft" v-bind:key="img.caption"
                                              :caption="img.caption" :img-src="img.url"></b-carousel-slide>
                        </b-carousel>
                    </b-col>
                    <b-col lg="6" order="2" order-lg="1" class="text-center text-lg-left">
                        <h4 class="m-0">
                            <b-badge variant="primary">Nebenhandlung</b-badge>
                        </h4>
                        <h2 class="mb-3">Banküberfall</h2>
                        <hr>
                        <p>
                            Im neuesten Update hast du die Möglichkeit ein Büro auf One-Island zu kaufen. Hier wirst du allerdings auf eine zwielichtige Gestalt treffen,
                            die einen Gefallen von dir erwartet, damit sie dein frisch gekauftes Büro und dich selbst in Frieden lässt.<br>
                            So viel ist klar: Es handelt sich nicht um einen einfachen Auftrag!
                        </p>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section class="mt-5">
            <b-container class="text-center">
                <h1>Story-FAQ</h1>

                <b-card class="faq-card-deck mt-4 px-3 pt-3">
                    <b-row v-for="(faq, id) in story_faqs" v-bind:key="id">
                        <b-col sm="auto" class="m-2 m-sm-0">
                            <b-img src="https://crafatar.com/avatars/606e2ff0ed7748429d6ce1d3321c7838" rounded height="20" width="20"></b-img>
                        </b-col>
                        <b-col class="text-left">
                            <p>
                                <strong>{{ faq.question }}</strong><br>
                                {{ faq.answer }}
                            </p>
                        </b-col>
                    </b-row>
                </b-card>
            </b-container>
        </section>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                gallery_img: {
                    chapter1: [
                        {
                            caption: 'Bürger Edward',
                            url: require('@/assets/img/story/chapter1/after_start_edward.png')
                        },
                        {
                            caption: 'Verfluchter Garten',
                            url: require('@/assets/img/story/chapter1/accursed_garden.png')
                        },
                        {
                            caption: 'Höhle des Infizierten',
                            url: require('@/assets/img/story/chapter1/cave_infected_rufi.png')
                        },
                        {
                            caption: 'Siegeshöhle',
                            url: require('@/assets/img/story/chapter1/final_cave_chest.png')
                        }
                    ],
                    chapter2: [
                        {
                            caption: 'Reise-Yacht',
                            url: require('@/assets/img/story/chapter2/tp_boat.png')
                        },
                        {
                            caption: 'Der Kapitän',
                            url: require('@/assets/img/story/chapter2/tp_boat_captain.png')
                        },
                        {
                            caption: 'Reisender Sparow',
                            url: require('@/assets/img/story/chapter2/paradise_island_sparow.png')
                        },
                        {
                            caption: 'Gesuchter Marvin',
                            url: require('@/assets/img/story/chapter2/evil_marvin_cave.png')
                        }
                    ],
                    banktheft: [
                        {
                            caption: 'Schneiderei Joguloa',
                            url: require('@/assets/img/story/banktheft/tailoring_joguloa.png')
                        },
                        {
                            caption: 'Bank',
                            url: require('@/assets/img/story/banktheft/bank_staff.png')
                        },
                        {
                            caption: 'Bank Tresor',
                            url: require('@/assets/img/story/banktheft/bank_safe.png')
                        },
                        {
                            caption: 'Merkwürdiger Kanaleingang',
                            url: require('@/assets/img/story/banktheft/strange_cave_entry.png')
                        }
                    ]
                },
                story_faqs: [
                    {
                        question: 'Wie lange dauert die Story?',
                        answer: 'Je nachdem wie viel Interesse du hast verschiedene Secrets und Einzelheiten während der Story zu entdecken, wirst du für den ersten Teil zwischen 20 und 40 Minuten brauchen.'
                    },
                    {
                        question: 'Wo finde ich die Story?',
                        answer: 'Du findest Edward am Hafen direkt am Spawn. Unterhalte dich mit ihm und er wird dir alles weitere erklären. Falls du innerhalb der Story nicht mehr weißt wo du bist, benutze den Story-Teleport in deinem Lobby-Kompass.'
                    },
                    {
                        question: 'Welche Belohnungen erhalte ich?',
                        answer: 'Du erhälst einige seltene Gadgets und Coins. Falls dir einige Knöpfe oder versteckte Schilder mit Secrets auffallen, benutze sie. Es lohnt sich!'
                    },
                    {
                        question: 'Kann ich die Story mehr als nur ein mal spielen?',
                        answer: 'Zum jetzigen Zeitpunkt hast du nur einen Spielstand. Also genieße die Story und schau dir alles genau an, denn so schnell kannst du sie nicht wieder spielen!'
                    },
                    {
                        question: 'Ich habe Ideen, Anregungen oder einen Bug gefunden.',
                        answer: 'Wir freuen uns über jedes Feedback. Schreib uns einfach über Discord, Teamspeak, oder Social Media. Wir versuchen in ein paar Tagen zu antworten.'
                    }
                ]
            }
        }
    }
</script>

<style>
    .start-modal-header {
        background-size: cover;
        background-image: url("../assets/img/story/chapter1/story_start_edward.png");
        height: 300px;
        color: white;
    }

    .start-modal-header > .close {
        color: white;
        opacity: 1;
    }

    @media (max-width: 576px) {
        .start-modal-header {
            height: calc(100vw / 1.75);
        }
    }

    .carousel-inner {
        border-radius: 0.25rem;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }
</style>

<style lang="scss">
    @import '~bootstrap/scss/bootstrap-grid';
    @include media-breakpoint-down(sm) {
        .carousel-caption {
            display: none !important;
        }
    }
</style>
<style lang="scss" scoped>
    @import '~bootstrap/scss/bootstrap-grid';

    .header-wrapper {
        background: linear-gradient(
                to bottom right,
                        rgba(55, 0, 1, 0.7),
                        rgba(15, 0, 0, 0.6)
        ), url('../assets/img/lobby.png') center/cover no-repeat border-box;
        padding-top: 50px;
    }

    .info-text {
        max-width: 700px;
    }

    .faq-card-deck {
        padding-bottom: -20px;
    }

    @include media-breakpoint-down(sm) {
        .btn-switch {
            display: block;
            margin: 1rem auto 1rem auto;
        }
    }
</style>
